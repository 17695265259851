import config from '../config'

// TM: Updated for hash and search sources for parameters
export const getURLParameterByName = (name) => {
    // look for a hash parameter
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    let value = (results === null) ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))

    // if not a hash, locate by URL Query parameter
    if(value === null) {
        value=URLSearchParams(window.location.search).get(name);
        if(value)
            value=decodeURIComponent(value);
    }
    return value;
}

export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

export const validateSubscription = (customerId, applicationId) => {
    return fetchWrapper(config.endpoints.subscription, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ customerId, applicationId })
    })
}

export default {
    getURLParameterByName,
    fetchWrapper,
    validateSubscription
}
const development = {
  purecloud: {
    name: "usw2.pure.cloud",
    initialPage: "https://wrapper.infsalesgroup.com",
    clientId: "adb26bd5-3296-4ab1-bf3b-30478bf35a57"
  },
  pureconnect: {
    primary: "",
    backup: "",
    //Leave identityProviderName blank if not using SSO and using IC auth
    //If using windows authentication, make this "Windows Authentication" exactly.
    identityProviderName: "Windows Authentication",
    baseUrl: "http://<hostname>:8018/icws",
    applicationName: "Genesys Template",
  },
  pureengage: {},
  product: "purecloud",
  endpoints: {
    subscription:
      "https://cwejblr0oe.execute-api.us-east-1.amazonaws.com/dev/subscription/validate",
  },
};

const production = {
  purecloud: {
    name: "usw2.pure.cloud",
    initialPage: "https://wrapper.infsalesgroup.com",
    clientId: "adb26bd5-3296-4ab1-bf3b-30478bf35a57",
  },
  pureconnect: {
    primary: "",
    backup: "",
    //Leave identityProviderName blank if not using SSO and using IC auth
    //If using windows authentication, make this "Windows Authentication" exactly.
    identityProviderName: "Windows Authentication",
    baseUrl: "http://<hostname>:8018/icws",
    applicationName: "Genesys Template",
  },
  pureengage: {},
  product: "purecloud",
  endpoints: {
    subscription:
      "https://ukv2z4due3.execute-api.us-east-1.amazonaws.com/prod/subscription/validate",
  },
};

const qa = {
  purecloud: {
    clientId: "",
  },
  pureconnect: {
    primary: "",
    backup: "",
    //Leave identityProviderName blank if not using SSO and using IC auth
    //If using windows authentication, make this "Windows Authentication" exactly.
    identityProviderName: "Windows Authentication",
    baseUrl: "http://<hostname>:8018/icws",
    applicationName: "Genesys Template",
  },
  pureengage: {},
  product: "purecloud",
  endpoints: {
    subscription:
      "https://y7vp7qnt6f.execute-api.us-east-1.amazonaws.com/test/subscription/validate",
  },
};

const env = "production";
let config;
switch (env) {
  case "production":
    config = production;
    break;
  case "development":
    config = development;
    break;
  case "qa":
    config = qa;
    break;
  default:
    break;
}

export default config;
export const headerTitle = "ISG Contact Center";

import React from "react";

const IframeToggle = ({ showGenesys }) => {
  // let isgoeSrcOld =
  //   "https://isg-br-isgoe05.infsalesgroup.com/isg/sales/userlogin.php";
    let isgoeSrc = "https://isg-br-web2/isg/balance/redirector.php";
 // let isgoeSrc = "https://isg-br-isgoe08/isg/sales/userlogin.php";
//  let genesysSrc = "https://login.usw2.pure.cloud";
  return (
    <>
      {/* <iframe
        title="genesys"
        id="genesys"
        className="iframe-view"
        src={genesysSrc}
        allow="camera *;microphone *"
        style={showGenesys ? { display: "block" } : { display: "none" }}
        // sandbox="allow-scripts,allow-same-origin,allow-forms,allow-modals"
      >
        "test"
      </iframe> */}
      <iframe
        title="isg"
        id="isg"
        className="iframe-view"
        src={isgoeSrc}
        style={showGenesys ? { display: "none" } : { display: "block" }}
        sandbox="allow-scripts allow-same-origin allow-forms allow-modals allow-popups"
      ></iframe>
    </>
  );
};

export default IframeToggle;

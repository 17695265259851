import config from "../config";
import {isLoggedIn} from "./purecloud";

const platformClient = window.require("platformClient");
const client = platformClient.ApiClient.instance;

//get APIs to use
const usersApi = new platformClient.UsersApi();
const notificationsApi = new platformClient.NotificationsApi();
const conversationsApi = new platformClient.ConversationsApi();
const routingApi = new platformClient.RoutingApi();

let user;

// TM: modified to use session stored access token
async function GetAuthorization() {
  client.setEnvironment(config.purecloud.name);
  //const auth = await client.loginImplicitGrant(config.purecloud.clientId, window.location.href);  // TM: commented
  let accessToken=sessionStorage.getItem('purecloud-csp-token')

  if(!isLoggedIn()){
    const auth = await client.loginImplicitGrant(config.purecloud.clientId, window.location.href);  // TM: commented
    accessToken = auth.accessToken
    sessionStorage.setItem('purecloud-csp-token', accessToken)
  }
  client.setAccessToken(accessToken);
  // client.setAccessToken(auth.accessToken); // TM: commented
}

// TM: Added presence definitions into user request
async function GetMe() {
  const options={
    expand: ["presence"]
  }
  user = await usersApi.getUsersMe(options);
  return user;
}

async function GetActiveConversation() {
  let activeConversations = await conversationsApi.getConversationsCalls();
  if (activeConversations.entities.length > 0) {
    let data = await conversationsApi.getConversationsCall(
      activeConversations.entities[0].id
    );
    return data;
  }
  return null;
}

async function GetInitialState() {
  await GetAuthorization();
  user = await GetMe();
  let activeConversation = await GetActiveConversation();

  let initialStateObject = {
    user: user,
    activeConversation: activeConversation,
  };
  return initialStateObject;
}

async function GetQueue(queueId) {
  try {
    return await routingApi.getRoutingQueue(queueId);
  }catch (e) {
    window.location.reload();
  }
}

async function GetChannels() {
  let opts = {
    includechannels: client.accessToken, // String | Show user's channels for this specific token or across all tokens for this user and app.  Channel Ids for other access tokens will not be shown, but will be presented to show their existence.
  };

  let channels = await notificationsApi.getNotificationsChannels(opts);
  let channelId;
  if (channels.entities.length > 0) {
    channelId = channels.entities[0].id;
  } else {
    channelId = await CreateANewChannel();
  }
  console.log(channelId);
  return channelId;
}

async function CreateANewChannel() {
  let newChannel = await notificationsApi.postNotificationsChannels();
  console.log(
    "Created a New Channel --------------------------" + newChannel.id
  );
  return newChannel.id;
}

function NotificationWebSocket(sChannelId) {
  var wsUri = `wss://streaming.${config.purecloud.name}/channels/` + sChannelId;
  let websocket = new WebSocket(wsUri);
  websocket.onopen = function (evt) {
    onOpen(sChannelId);
  };
  websocket.onmessage = function (evt) {
    onMessage(evt);
  };
  websocket.onerror = function (evt) {
    onError(evt);
  };
}

async function onOpen(sChannelId) {
  let opts = {
    expand: ["expand_example"], // [String] | Which fields, if any, to expand
    includePreview: true, // Boolean | Whether or not to include Preview topics
  };

  let availableTopics = await notificationsApi.getNotificationsAvailabletopics(
    opts
  );
  // console.log(availableTopics.entities);

  var sdata = availableTopics;
  var notificationId = "";
  for (var i = 0; i < sdata.entities.length; i++) {
    if (
      sdata.entities[i].id.indexOf("v2.users.{id}.conversations.calls") !== -1
    ) {
      notificationId = sdata.entities[i].id;
      break;
    }
  }
  var stindex = notificationId.indexOf("{");
  var endindex = notificationId.indexOf("}");
  notificationId =
    notificationId.substr(0, stindex) +
    user.id +
    notificationId.substr(endindex + 1, notificationId.length);
  SubscribeToNotification(sChannelId, notificationId);
}

function onMessage(evt) {
  var selecteddata = JSON.parse(evt.data);
  const conv_interactionId = selecteddata.eventBody.id;

  if (selecteddata.topicName.includes("calls")) {
    if (selecteddata.eventBody !== null) {
      if (selecteddata.eventBody.participants !== null) {
        Sync_GetConversation(conv_interactionId);
      }
    }
  }
}

function onError(evt) {
  console.log(evt.data);
}

async function SubscribeToNotification(channelId, notificationId) {
  let body = [{ id: notificationId }]; // Object | Body
  try {
    let notificationSubscription =
      await notificationsApi.postNotificationsChannelSubscriptions(
        channelId,
        body
      );
    console.log(
      `postNotificationsChannelSubscriptions success! data: ${JSON.stringify(
        notificationSubscription,
        null,
        2
      )}`
    );
  } catch (error) {
    console.log(
      "There was a failure calling postNotificationsChannelSubscriptions"
    );
    console.error(error);
  }
}

async function Sync_GetConversation(conversationId) {
  let data = await conversationsApi.getConversationsCall(conversationId);

  console.log("Sync_GetConversation", data);
  var IsCustomerConfined = false;

  if (
    data !== undefined &&
    data !== null &&
    data.participants !== undefined &&
    data.participants !== null &&
    data.participants.length > 0
  ) {
    var participants = data.participants;

    var connectedParticipants = participants.filter(function (el) {
      return (
        el.state === "connected" ||
        el.state === "dialing" ||
        el.state === "offering" ||
        el.state === "alerting"
      );
    });

    // console.log("Sync_GetConversation", connectedParticipants);

    var customerDetails = participants.filter(function (el) {
      return el.purpose === "customer";
    });
    // console.log("Sync_GetConversation", customerDetails);

    if (
      customerDetails !== undefined &&
      customerDetails !== null &&
      customerDetails.length > 0
    ) {
      IsCustomerConfined = customerDetails[0].confined;
    }

    for (var i = 0; i < participants.length; i++) {
      if (
        (participants[i].purpose === "user" ||
          participants[i].purpose === "agent") &&
        participants[i].user.id === user.id
      ) {
        if (participants[i] !== undefined && participants[i] !== null) {
          // console.log(data.id);
          // console.log(
          //   "Call Status: " +
          //     participants[i].state +
          //     "       Hold Status " +
          //     participants[i].held +
          //     "   RECORD STATE " +
          //     participants[0].recordingState +
          //     "   RECORD FLAG " +
          //     participants[i].recording
          // );

          if (participants[i].state === "connected" && !participants[i].held) {
            if (connectedParticipants.length < 3) {
              if (data.recordingState !== "active") {
                // console.log("Start Rec");
                // StartStopRecording(data.id, "active");
              }
            } else {
              //Handle Consult Call
              if (!IsCustomerConfined) {
                if (data.recordingState !== "active") {
                  console.log("Start Rec");
                  StartStopRecording(data.id, "active");
                }
              } else if (data.recordingState !== "paused") {
                console.log("Stop Rec");
                document.getElementById("recordingState").innerText = "Paused";
                document.getElementById("recordingState").style.color = "red";
                StartStopRecording(data.id, "paused");
              }
            }
          } else if (participants[i].state === "connected") {
            if (data.recordingState !== "paused") {
              console.log("Stop Rec");
              // document.getElementById("recordingState").innerText = "Paused";
              // document.getElementById("recordingState").style.color = "red";
              // StartStopRecording(data.id, "paused");
            }
          }
        }
      }
    }
  }
}

async function StartStopRecording(conversationId, record, yourAccessToken) {
  platformClient.ApiClient.instance.setAccessToken(yourAccessToken);

  let apiInstance = new platformClient.ConversationsApi();

  let body = {}; // Object | Conversation

  apiInstance
    .patchConversationsCall(conversationId, body)
    .then((data) => {
      if (record === "active") {
        document.getElementById("recordingState").innerText = "Active";
        document.getElementById("recordingState").style.color = "green";
      } else if (record === "paused") {
        document.getElementById("recordingState").innerText = "Paused";
        document.getElementById("recordingState").style.color = "red";
      }
      console.log("Call Recording Status- " + record);

      console.log(
        `patchConversationsCall success! data: ${JSON.stringify(data, null, 2)}`
      );
    })
    .catch((err) => {
      console.log("There was a failure calling patchConversationsCall");
      console.error(err);
    });
}

export {
  GetAuthorization,
  GetMe,
  GetChannels,
  GetInitialState,
  NotificationWebSocket,
  notificationsApi,
  conversationsApi,
  routingApi,
  GetQueue,
};

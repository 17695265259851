import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getURLParameterByName } from '../services/utils'
import config from '../config';

/*
    TM: added this page to be embedded in Genesys Cloud UI that obtains the 
    access code and launches a browser instance of the application.

    The purpose is to inhibit the need to perform a second login to Genesys
    on running the application in its own browser instance
*/

const Launch = () => {
    const location = useLocation()
    const history = useHistory()
    const [token, setToken] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            let env = new URLSearchParams(location.search).get('environment')
            if (!env) env = config.purecloud.name
            sessionStorage.setItem('purecloud-csp-env', env)
            console.log('ISGW: environment:', env)

            // if there is an access_token parameter, then store it
            let accessToken=getURLParameterByName('access_token');
            if (accessToken) {
                console.info(`ISGW: Access token supplied as query parameter`)
                sessionStorage.setItem('purecloud-csp-token', accessToken)
                setToken(accessToken);
            } else {
                // no access_token, try to use session storage
                accessToken=sessionStorage.getItem('purecloud-csp-token')
                if (accessToken) {
                    console.info("ISGW: using stored token");
                    setToken(accessToken);
                }
                else {
                    // no access_token and no stored token, force a login
                    console.info("ISGW: redirect to login - should be logged into Genesys Cloud first");
                    history.push('/login')
                }
            }
        }
        initialize();
        // eslint-disable-next-line
    }, [])

    const onSubmit = (event) => {
    }

    return token ? (
        <form onSubmit={onSubmit} method="GET" target="_blank" action={`http://localhost:3000`} style={{margin: "2px"}}>
            <label >Launching a browser for ISGOE. You can close this widget after the browser appears.</label>
            <div style={{ display: "block"}}>
                <input type="submit" value="Launch ISGOE Browser" />
            </div>
                <input type='hidden' name='access_token' value={token}/>

        </form>
        ) 
        : ( <Fragment></Fragment> );
    
}

export default Launch
import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import config from '../config'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import PureCloudLogin from '../components/login/purecloud/PureCloudLogin'
import PureConnectLogin from '../components/login/pureconnect/PureConnectLogin'
import Home from '../components/home/Home'
import Main from '../components/Main'
import Launch from '../components/Launch'
import Unauthorized from '../components/unauthorized/Unauthorized'

/*
    TM: Added Launch route for browser instance management
*/

const history = createBrowserHistory()
const LoginPage = config.product === 'purecloud' ? PureCloudLogin : config.product === 'pureconnect' ? PureConnectLogin : 'pureengage'
const AppRouter = () => (
    <Router history={history}>
        <Switch>
            <PublicRoute path="/" exact={true} component={Main} history={history} />
            <PublicRoute path="/login" component={LoginPage} />
            <PublicRoute path="/unauthorized" component={Unauthorized} />
            <PrivateRoute path="/home" component={Home} history={history} />
            <PublicRoute path="/launch" component={Launch} history={history} />
        </Switch>
    </Router>
)

export default AppRouter
import React, { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { getURLParameterByName } from '../services/utils'
import config from '../config';

const Main = () => {
    const history = useHistory()

    // TM: Updated for URL parameter handling
    useEffect(() => {
        const purecloudInitialization = async () => {
            let env = getURLParameterByName('environment')
            if (!env) env = config.purecloud.name       
            sessionStorage.setItem('purecloud-csp-env', env)
            console.log('ISGW: environment:', env)

            // TM: Updated for logging
            const accessToken=getURLParameterByName('access_token');
            if (accessToken) {
                sessionStorage.setItem('purecloud-csp-token', accessToken)
                console.info(`ISGW: Got token from URL params`)
            }
            if (sessionStorage.getItem('purecloud-csp-token')) {
                console.info("ISGW: Loaded token from session storage");
                try {
                    history.push('/home')
                } catch (error) {
                    history.push('/unauthorized', error.message)
                }
            }
            else {
                console.info("ISGW: redirect to login - should be logged into Genesys Cloud first");
                history.push('/login')
            }
        }
        const pureconnectInitialization = () => {
            if (sessionStorage.getItem('pureconnect-csrf-token')) {
                history.push('/home')
            } else {
                history.push('/login')
            }
        }
        switch (config.product) {
            case 'purecloud': purecloudInitialization()
                break;
            case 'pureconnect': pureconnectInitialization()
                break;
            case 'pureengage':
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [])

    return (<Fragment></Fragment>)
}

export default Main
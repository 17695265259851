import React, {useState, useEffect} from "react";
import {Grid} from "@mui/material";

const CallDataGrid = ({conversation}) => {
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            if (conversation != null) {
                let convoCustomer = conversation.participants.find(
                    (p) => p.purpose === "customer"
                );
                if (convoCustomer) {
                   /* let queueInfo = await GetQueue(convoCustomer.queue.id);*/
                    convoCustomer.queueName = convoCustomer.queue.id;
                    setCustomer(convoCustomer);
                } else {
                    let convOutbound = conversation.participants.find(
                        (p) => p.direction === "outbound" && p.purpose === "external" && p.state === 'connected'
                    );
                    if(convOutbound){
                        setCustomer(convOutbound);
                    }
                }
            }
        };

        initialize();
    }, [conversation]);

    const DnisQueueGrid = () => {
        return (
            <Grid container item xs={1} direction={"column"}>
                <Grid
                    item
                    style={{
                        border: "1px solid lightgray", marginLeft: "2px", textAlign: "center",
                        fontSize: "14px",
                        position: "relative",
                        marginTop: "10px",
                        top: "-5px",
                        paddingTop: "8px",
                        height: "39px",
                        borderRadius: "5px",
                        boxShadow: "0px 1px 4px #d4d4d4",
                        textTransform: "uppercase",
                        color: "darkBlue",
                        minWidth: "270px",
                    }}
                >
                    <b>DNIS: </b>
                    {customer["attributes"] === undefined || Object.keys(customer["attributes"]).length === 0 ? 'Outbound' : customer.attributes.DNIS}
                    <label style={{margin: "0px 10px", fontWeight: "600"}}>|</label>

                    <b>ANI: </b>{checkAni(customer.address)}
                </Grid>
            </Grid>
        );
    };

    const checkAni = (ani) => {
        let aniInfo = ani.replace(/\D/g, "");

        let blockAny = [
            '15619015506',
            '12095506110',
            '18336927773',
            '13122340781',
            '13045506000',
            '14193463929',
            '19198399904',
            '19097062822',
            '18166066205',
            '17166057137',
            '17193389285',
            '17067419755',
            '15858802626',
            '12162373998',
            '12483613395',
            '12163790097',
            '18003471991',
            '18002014099',
            '19257529690'
        ];

        return blockAny.includes(aniInfo) ? 'N/A' : aniInfo;
    }

    const AniInteractionGrid = () => {
        return (
            <Grid container item xs={1} direction={"column"}>

                <Grid
                    item
                    style={{
                        border: "1px solid lightgray", marginLeft: "2px", textAlign: "center",
                        fontSize: "14px",
                        position: "relative",
                        marginTop: "10px",
                        top: "16px",
                        borderRadius: "5px",
                        boxShadow: "0px 1px 4px #d4d4d4",
                        textTransform: "uppercase",
                        color: "darkBlue",
                    }}
                >
                    <b>ANI: </b> {customer.address.replace(/\D/g, "")}
                </Grid>
            </Grid>
        );
    };

    const InteractionGrid = () => {
        return (
            <Grid
                item
                style={{
                    border: "1px solid lightgray", marginLeft: "2px", padding: "0px 8px", textAlign: "center",
                    fontSize: "14px",
                    position: "relative",
                    marginTop: "10px",
                    top: "-5px",
                    height: "39px",
                    paddingTop: "8px",
                    borderRadius: "5px",
                    boxShadow: "0px 1px 4px #d4d4d4",
                    textTransform: "uppercase",
                    color: "darkBlue"
                }}
            >
                <b>Interaction Id: </b>
                {conversation.id}
            </Grid>
        );
    };


    const NoteSelectionGrid = () => {
        return (
            <Grid container item xs={1} direction={"column"}>
                <Grid item style={{
                    marginLeft: "2px", textAlign: "center",
                    fontSize: "14px",
                    position: "relative",
                    marginTop: "10px",
                    top: "6px",
                    width: "75vw",
                    marginBottom: "10px",
                    color: "darkBlue"
                }}>
                    <b>Alert: </b>
                    Make sure to log out from the Genesys application at the end of your shift.
                </Grid>
            </Grid>
        );
    };

    const TopHalf = () => {
        return (
            <Grid container justifyContent="space-between" columns={3}>
                <DnisQueueGrid/>
                {/* <AniInteractionGrid /> */}
                <InteractionGrid/>
            </Grid>
        );
    };

    const BottomHalf = () => {
        return false
    };

    return (
        <Grid item container xs={8} alignItems={"center"}>
            {customer === null || conversation === null ? (
                <></>
            ) : (
                <>
                    <TopHalf/>
                    {<BottomHalf/>}
                </>
            )}
        </Grid>
    );
};

export default CallDataGrid;
{
}
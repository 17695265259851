import React from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        component={(props) => (
            <div className="public-route">
                <Component {...props} />
            </div>
        )}
    />
)

export default PublicRoute
export const fetchWrapper = async (url, init) => {
    const response = await fetch(url, init)
    let correlationId
    for (let pair of response.headers.entries()) {
        if (pair[0] === 'inin-correlation-id') {
            correlationId = pair[1]
            break
        }
    }
    const json = await response.json()
    return response.ok
        ? { ...json, correlationId }
        : Promise.reject({ ...json, correlationId })
}

export const isLoggedIn = () => {
    return sessionStorage.getItem('purecloud-csp-token') !== null
}

export const getOrganization = (environment, token) => {
    console.log(`getOrganization.env:${environment}, token:${token}`)
    return fetchWrapper(`https://api.${environment}/api/v2/organizations/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    })
}

export default {
    isLoggedIn,
    fetchWrapper,
    getOrganization
}
import "./Home.css";
import React, { useState, useEffect } from "react";
import { Button, Grid, ButtonGroup } from "@mui/material";
import config from '../../config';

import CallDataGrid from "../call_data_grid/CallDataGrid";
import IframeToggle from "../iframe_toggle/IframeToggle";

import { GetInitialState, notificationsApi, conversationsApi, GetQueue } from "../../services/apiUtils";

let notificationWebsocket = null;
var Interval;
let timeRemaining;
let defaultPauseTime = 45;
let forceLogout=false;

const Home = () => {
  const [user, setUser] = useState(null);
  const [statusColor, setStatusColor] = useState("lightGray");
  const [loggedIn, setLoggedIn] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [pauseTime, setPauseTime] = useState(defaultPauseTime);
  const [loading, setLoading] = useState(true);
  const [showGenesys] = useState(false);
  const [eventList] = useState([]);

  //initialize
  useEffect(() => {
    const initialize = async () => {
      try {
        let { activeConversation, user } = await GetInitialState();
        if (activeConversation) {
          console.info("ISGW: Has an active conversation in progress");
          let agent = activeConversation.participants.find((p) => (p.purpose === "agent") || (p.direction === "outbound" &&  p.purpose === "user" && p.user !== undefined) );
          //handle "active" conversations in wrap-up state
          if (agent.state === "disconnected" || agent.state === "terminated") {
            setConversation(null);
          } else {
            createMessage(activeConversation);
            setConversation(activeConversation);
          }
        }
        setUser(user);
        setLoggedIn(true);

        // TM: set user presence status
        if(user!==null && user.presence)
          if(user.presence.presenceDefinition && user.presence.presenceDefinition.systemPresence)
            setPresenceStatus(user.presence.presenceDefinition.systemPresence)

        setLoading(false);

        window.addEventListener('beforeunload', alertUser, true)
        window.addEventListener('unload', handleTabClosing, true)
        // return () => {
        //   window.removeEventListener('beforeunload', alertUser, true)
        //   window.removeEventListener('unload', handleTabClosing, true)
        // }
      } catch (error) {
        console.log(error);
      }
    };

    initialize();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setNotificationService();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    updateNotificationService();
    // eslint-disable-next-line
  }, [conversation]);


  // TM: forced and simple logout handling
  // forced logout's perform no user request to close page
  // prompt logout's add the browser handlers to confirm the logout

  const logout = () => {
    // remove any prompting event listeners
    forceLogout=true

    // clear any stored tokens
    sessionStorage.removeItem('purecloud-csp-token')
console.log(`https://login.${config.purecloud.name}/logout?client_id=${config.purecloud.clientId}&redirect_uri=${config.purecloud.initialPage}`);
//    window.location.href = `https://login.${config.purecloud.name}/logout`;
   window.location.href = `https://login.${config.purecloud.name}/logout?client_id=${config.purecloud.clientId}&redirect_uri=${config.purecloud.initialPage}`;

  };

  const handleTabClosing = () => {
    logout();
  }

  const alertUser = (event) => {
    if(forceLogout === false) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  const createMessage = async (conversation) => {
    let callState;

    let agents = conversation.participants.filter((p) => (p.purpose === "agent") || (p.direction === "outbound" &&  p.purpose === "user" && p.user !== undefined));
    if (agents.length > 0) {
      const agent = agents[agents.length - 1];

      if (agent.wrapup) {
        callState = "EndInteraction";
      } else if (agent.state === "connected") {
        callState = "ConnectedCall";
      } else if (agent.state === "terminated") {
        callState = "EndCall";
      } else {
        callState = "NewCall";
      }

      // determine if we have sent the callState event previously for this conversation
      // if not, record and send
      // if have, do nothing
      // otherwise update last recorded state
      const ele = eventList.find((li) => li.conversationId === conversation.id);
      if (ele === undefined) {
        eventList.push({ conversationId: conversation.id, eventsSent: [callState] });
      } else {
        if (ele.eventsSent.includes(callState)) return;
        else ele.eventsSent.push(callState);
      }

      // send event
      let queue = {'name':'Outbound'};
      if(agent.queue !== undefined){
        if(agent.queue.id !== undefined){
          queue = agent.queue.id;
        }
      }

      let messageData = {
        action: callState,
        conversation: conversation,
        queueName: queue,
        user: user,
      };

      let receiver = document.getElementById("isg").contentWindow;

      receiver.postMessage(messageData, "*");

      console.info(`Sent callState: ${callState}`);
    }
  };

  // TM: for setting presence indicator value
  const setPresenceStatus = (status) => {
    setLoggedIn(true);
    switch(status.toUpperCase()) {
      case "OFFLINE":
        setLoggedIn(false);
        setStatusColor("lightGray")
        break;
      // set colors for ONLINE presence
      case "AVAILABLE":
          setStatusColor("#7D2")    // Genesys presence green
        break;
      case "MEETING":
      case "BUSY":
        setStatusColor("red")
        break;
      case "AWAY":
      case "BREAK":
      case "MEAL":
      case "TRAINING":
        setStatusColor("#FA0")    // Genesys presence yellow
        break;
      case "OUT_OF_OFFICE":
        setStatusColor("#FF1DCE") // Genesys presence mauve
        break;
      case "ON_QUEUE":
        setStatusColor("#52CEF8") // Genesys presence light blue
        break;
      default:
        setStatusColor("red")    // Genesys presence red
      }
  }

  const handleNotification = async (message) => {
    // only process message if it contains data
    if (message.data) {
      const data = JSON.parse(message.data); // data is a structured string, parse it

      // ignore heartbeat messages
      if (data.eventBody && data.eventBody.message === "WebSocket Heartbeat") return;

      // process presence change events
      if (data.eventBody) {
        console.info("ISGW: Non-heartbeat event received");

        // TM: Added for logout handling
        // if a presence message, check if OFFLINE, if so, force a logout condition
        if(data.eventBody.presenceDefinition) {
          if(data.eventBody.presenceDefinition.systemPresence === "OFFLINE") {
            forceLogout();
          }

          // set presence color
          setPresenceStatus(data.eventBody.presenceDefinition.systemPresence)
        }
        else {
          // conversation message
          createMessage(data.eventBody);
          setConversation(data.eventBody);
          if (conversation) {
            let updateRecordingStatus = data.eventBody.recordingState !== conversation.recordingState.toLowerCase();
            if (updateRecordingStatus) {
              if (data.eventBody.recordingState === "paused") {
                handleStartPause();
              } else {
                handleResumeRecording();
              }
            }
          }
          if (data.eventBody.participants) {
            let callParticipants = data.eventBody.participants;
            let agents = callParticipants.filter((p) => p.purpose === "agent");
            if (agents.length > 0) {
              let agent = agents[agents.length - 1];
              if (agent.state === "disconnected" || agent.state === "terminated") {
                setConversation(null);
              }
            }
          }
        }
      }
    }
  };

  const setNotificationService = async () => {
    const handleClose = async (message) => {
      // close the notification subsystem
    };

    const handleError = async (message) => {
      // handle the error
    };

    if (user != null) {
      console.info("ISGW: Creating notification");
      const notification = await notificationsApi.postNotificationsChannels();
      // // create the web socket
      notificationWebsocket = new WebSocket(notification.connectUri);

      // set up the notification handlers
      notificationWebsocket.onmessage = handleNotification;
      notificationWebsocket.onclose = handleClose;
      notificationWebsocket.onerror = handleError;

      // subscribe to the topic
      // TM: Added user presence for automating logout
      const subscriptionBody = [{ id: `v2.users.${user.id}.conversations.calls` },
                                { id: `v2.users.${user.id}.presence` }
                              ];

      await notificationsApi.putNotificationsChannelSubscriptions(notification.id, subscriptionBody);
      console.info("ISGW: Notification subscription created");
    } else {
      console.info("ISGW: Cannot create notification, no user logged into Genesys Cloud");
    }
  };

  const updateNotificationService = async () => {
    if (notificationWebsocket) {
      notificationWebsocket.onmessage = handleNotification;
    }
  };

  async function startTimer() {
    timeRemaining--;
    setPauseTime(timeRemaining);
    if (timeRemaining < 1) {
      clearInterval(Interval);
      conversation.recordingState = "ACTIVE";
      await conversationsApi.patchConversationsCall(conversation.id, conversation);
      setPauseTime(defaultPauseTime);
    }
  }

  function handleStartPause() {
    clearInterval(Interval);
    conversation.recordingState = "PAUSED";
    timeRemaining = defaultPauseTime;
    Interval = setInterval(() => startTimer(), 1000);
  }

  function handleExtendPause() {
    clearInterval(Interval);
    timeRemaining = defaultPauseTime;
    setPauseTime(timeRemaining);
    Interval = setInterval(() => startTimer(), 1000);
  }

  function handleResumeRecording() {
    clearInterval(Interval);
    timeRemaining = defaultPauseTime;
    setPauseTime(timeRemaining);
  }

  async function startStopRecording(recording) {
    let validValues = ["ACTIVE", "PAUSED", "NONE"];
    if (validValues.includes(recording)) {
      if (recording === "PAUSED") {
        handleStartPause(pauseTime, setPauseTime, conversation, recording);
      }
      if (recording === "ACTIVE") {
        handleResumeRecording();
      }
      conversation.recordingState = recording;
      await conversationsApi.patchConversationsCall(conversation.id, conversation);
    }
  }

  const PauseResumeButtonGroup = () => {
    const NoActiveCall = () => {
      return (
        <Button variant="contained" disabled id="nocall">
          No call in progress
        </Button>
      );
    };

    const RecordingActiveCall = () => {
      return (
        <Button variant="contained" onClick={() => startStopRecording("PAUSED")} id="pause-record-btn">
          Pause Recording
        </Button>
      );
    };

    const PausedActiveCall = () => {
      return (
        <ButtonGroup aria-label="outlined primary button group" justifyContent="space-between" display="flex">
          <Button onClick={() => startStopRecording("ACTIVE")} id="record-btn" className="my-btn">
            Resume Recording
          </Button>
          <div style={{ width: "10px" }}></div>
          <Button variant="outlined" id="extendPause" className="my-btn" onClick={handleExtendPause}>
            {" "}
            {pauseTime} - Extend{" "}
          </Button>
        </ButtonGroup>
      );
    };

    return (
      <Grid container item xs={2} alignItems="center" justifyContent={"flex-end"} className="pause-resume-btn-grp">
        {loading ? (
          <></>
        ) : (
          <>
            {conversation ? (
              conversation.recordingState === "active" || conversation.recordingState === "none" ? (
                <RecordingActiveCall />
              ) : (
                <PausedActiveCall />
              )
            ) : (
              <NoActiveCall />
            )}
          </>
        )}
      </Grid>
    );
  };
  return loading ? (
    <></>
  ) : (
    <div className="main-div">
      <Grid container style={{ display:"flex", justifyContent:"space-between", boxShadow:"rgb(212 212 212) 0px 1px 4px", paddingBottom:"1px"}}>
        <Grid item >
            <div style={{ height: "100%", width: "10px", backgroundColor: statusColor,
          }}><span></span></div>
        </Grid>
        <Grid item >
          <Grid container style={{paddingLeft: "5px", paddingRight: "5px", height: "100%"}} alignItems="center" >
              <Button  style={{position: "absolute",
          left: "12px",
          height: "33px"}}  variant="contained" id="logout" disabled={loggedIn?false: true} onClick={logout}>Status</Button>
          </Grid>
        </Grid>
        <CallDataGrid conversation={conversation} />
        <PauseResumeButtonGroup style={{position:"relative", top:"-20px"}} />
      </Grid>
      <Grid container style={{ display:"flex", justifyContent:"center", paddingBottom:"5px"}}>
        <Grid item style={{
          marginLeft: "11px",
          fontSize: "14px",
          position: "relative",
          marginTop: "5px",
          paddingLeft : "10px",
          top: "6px",
          width: "60vw",
          marginBottom: "10px",
          textAlign: "center",
          color: "darkBlue",
          borderRadius: "5px",
          border: "1px solid lightGray  ",
          boxShadow: "rgb(200 200 200) -1px 3px 4px",
        }} >
        <b>💬 </b>
          Make sure to log out from the Genesys application at the end of your shift.(v1.0.1)
        </Grid>
      </Grid>
      {<IframeToggle showGenesys={showGenesys}></IframeToggle>}
    </div>
  );
};



export default Home;
